import React from "react";
import { Link } from "react-router-dom";

function NavBar() {
  return (

    <nav className="navbar navbar-expand-lg navbar-light" id="navbar">
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link to="/">Home</Link>
          </li>
          <li className="nav-item">
            <Link to="about">About</Link>
          </li>
          <li className="nav-item">
            <Link to="instructors">Instructors</Link>
          </li>
          <li className="nav-item">
            <Link to="schedule">Schedule</Link>
          </li>
          <li className="nav-item">
            <Link to="offers">Offers</Link>
          </li>
          <li className="nav-item">
            <Link to="curriculum">Curriculum</Link>
          </li>
        </ul>
      </div>
    </nav>

    // <Navbar collapseOnSelect={true}>
    //   <Navbar.Toggle aria-controls="basic-navbar-nav" />
    //   <Navbar.Collapse id="navbar">
    //     <Nav className="mr-auto">
    //       <Nav.Item><Link to="/">Home</Link></Nav.Item>
    //       <Nav.Item><Link to="about">About</Link></Nav.Item>
    //       <Nav.Item><Link to="programs">Programs</Link></Nav.Item>
    //       <Nav.Item><Link to="instructors">Instructors</Link></Nav.Item>
    //       <Nav.Item><Link to="schedule">Schedule</Link></Nav.Item>
    //       <Nav.Item><Link to="offers">Offers</Link></Nav.Item>
    //       <Nav.Item><Link to="curriculum">Curriculum</Link></Nav.Item>
    //     </Nav>
    //   </Navbar.Collapse>
    // </Navbar>
    // <div id="navbar">
    //   <nav>
    //     <div className="row navbar" style={{background: "rgba(241, 249, 248, .9)"}}>
    //       <div className="col-2">
    //         <Link to="about">About</Link>
    //       </div>
    //       <div className="col-2">
    //         <Link to="programs">Programs</Link>
    //       </div>
    //       <div className="col-2">
    //         <Link to="instructors">Instructors</Link>
    //       </div>
    //       <div className="col-2">
    //         <Link to="schedule">Schedule</Link>
    //       </div>
    //       <div className="col-2">
    //         <Link to="offers">Offers</Link>
    //       </div>
    //       <div className="col-2">
    //         <Link to="curriculum">Curriculum</Link>
    //       </div>
    //     </div>
    //   </nav>
    // </div>
  );
}

export default NavBar;
