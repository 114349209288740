import React, {Component} from 'react';

import offerImage from './img/offers-new-student.jpg';

class Offers extends Component {
  
  render(){
    return (
<div className="row">
    <div className="box">
        <div className="col-12">
            <hr />
            <h2 className="intro-text text-center">
                <strong>Special Offers</strong>
            </h2>
            <hr />
            <img className="img-responsive img-border img-left" src={offerImage} height="50%" width="50%" alt="New Student Offer" />
            <h2 style={{textAlign: 'center'}}>Limited Time Offer: Two Free Classes</h2>
            <p style={{textAlign: 'center'}}>Want to try Taekwondo? Come experience World Class Taekwondo Training!</p>
            <p style={{textAlign: 'center'}}>Join us for two free classes.</p>
        </div>
    </div>
</div>

    );
  }
}

export default Offers;