import React, { Component } from 'react';
import Video from './Video';

interface ICurriculumState {
  loggedIn: boolean | string;
  username: string;
  password: string;
  error: string;
  section: string;
}

// it's a pandemic, so we just need to rush to get this out
// eventually we should swap with a server-side call
const user = "kiantkd";
const password = "kiantkd2020";

class Programs extends Component<ICurriculumState, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      loggedIn: false,
      username: "",
      password: "",
      error: "",
      section: "login",
    }

    this.updateField = this.updateField.bind(this);
    this.checkEnter = this.checkEnter.bind(this);
    this.login = this.login.bind(this);
    this.getSection = this.getSection.bind(this);
  }

  componentDidMount() {
    let loggedIn = false;
    let foundLoggedIn = localStorage.getItem("loggedIn");
    if (foundLoggedIn && typeof foundLoggedIn === "string") {
      loggedIn = foundLoggedIn === "true" ? true : false;
    }
    if (loggedIn) {
      this.setState({ loggedIn: true, section: "virtualClass" });
    }
  }

  render() {
    return (
      <div id="curriculum">
        <div className="row">
          <div className="col-lg-3 col-sm-12">
            <div className="box">
              <hr />
              <h2 className="intro-text text-center">
                <strong>Curriculum</strong>
              </h2>
              <hr />
              <div className="curriculum-link" onClick={() => this.setSection("stretching")}>Stretching</div>
              <div className="curriculum-link" onClick={() => this.setSection("stances")}>Stances</div>
              <div className="curriculum-link" onClick={() => this.setSection("basicTechniques")}>Basic Techniques</div>
              <div className="curriculum-link" onClick={() => this.setSection("basicMotion")}>Basic Motion</div>
              <div className="curriculum-link" onClick={() => this.setSection("sabangchuck")}>Sabangchuk</div>
              <div className="curriculum-link" onClick={() => this.setSection("forms")}>Forms</div>
              <div className="curriculum-link" onClick={() => this.setSection("poomsae")}>Poomsae</div>
              <div className="curriculum-link" onClick={() => this.setSection("targetKicking")}>Target Kicking</div>
              <div className="curriculum-link" onClick={() => this.setSection("breaking")}>Breaking</div>
              <div className="curriculum-link" onClick={() => this.setSection("knowledge")}>Knowledge</div>
            </div>
          </div>
          <div className="col-lg-9 col-sm-12">
            {this.getSection()}
          </div>
        </div>
      </div>
    );
  }

  updateField(e: any) {
    const ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private checkEnter(e: any){
    if(e.keyCode === 13) {
      this.login();
    }
  }

  setSection(section: string) {
    this.setState({ section });
  }

  login() {
    // TODO: replace the login with a remote call
    // but it is a pandemic after all
    if (this.state.username === user && this.state.password === password) {
      this.setState({ error: "", loggedIn: true, section: "virtualClass" }, () => {
        localStorage.setItem("loggedIn", "true");
      });
    } else {
      this.setState({ error: "Incorrect information" });
    }
  }

  getSection() {
    if (this.state.section === "login" || !this.state.loggedIn) {
      return (

        <div className="box">
          <hr />
          <h2 className="intro-text text-center">
            <strong>Login</strong>
            <p className="small" style={{ marginTop: 10 }}>You must login to access the curriculum</p>
          </h2>
          <div className="form-group">
            <label>Username</label>
            <input type="text" id="username" className="form-control" value={this.state.username} onChange={this.updateField}  onKeyUp={this.checkEnter} />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input type="password" id="password" className="form-control" value={this.state.password} onChange={this.updateField}  onKeyUp={this.checkEnter} />
          </div>
          {this.state.error !== "" && (
            <div className="form-group">
              <span className="text-danger">{this.state.error}</span>
            </div>
          )}
          <div className="form-group">
            <button className="btn btn-block btn-primary" onClick={this.login}>Login</button>
          </div>
        </div>
      )
    }
    switch (this.state.section) {
      case "stretching":
        return this.getStretching();
      case "stances":
        return this.getStances();
      case "basicTechniques":
        return this.getBasicTechniques();
      case "basicMotion":
        return this.getBasicMotion();
      case "sabangchuck":
        return this.getSabanchuk();
      case "forms":
        return this.getForms();
      case "poomsae":
        return this.getPoomsaes();
      case "targetKicking":
        return this.getTargetKicking();
      case "breaking":
        return this.getBreaking();
      case "knowledge":
        return this.getKnowledge();
      default:
        return this.getStretching();
    }
  }

  getStretching() {
    return (
      <div className="box">
        <hr />
        <h2 className="intro-text text-center" style={{ fontWeight: "bold" }}>
          Stretching
                </h2>
        <div className="row">
          <div className="col-12">
            <Video title="" url="https://www.youtube.com/embed/gjSVsQWjPIo" />
          </div>
        </div>
      </div>
    );
  }

  getStances() {
    return (
      <div className="box">
        <hr />
        <h2 className="intro-text text-center" style={{ fontWeight: "bold" }}>
          Stances
                </h2>
        <div className="row">
          <div className="col-12">
            <Video title="Attention" url="https://www.youtube.com/embed/OJD9dFjPY68" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video title="Ready" subtitle="Mirror Image" url="https://www.youtube.com/embed/CGuctY7mllg" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video title="Horseback Riding Stance" subtitle="Mirror Image" url="https://www.youtube.com/embed/1pfjW6Usbhc" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video title="Walking Stance" url="https://www.youtube.com/embed/vW34OWVpIE8" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video title="Kicking Stance" subtitle="Mirror Image" url="https://www.youtube.com/embed/kddyhLPBSkU" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video title="Forward Stance" url="https://www.youtube.com/embed/8P6c2MrpXUo" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video title="Back Stance" url="https://www.youtube.com/embed/QuDPvJ7llBw" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video title="Tiger Stance" url="https://www.youtube.com/embed/ZHKQKvhfMAY" />
          </div>
        </div>
      </div>
    );
  }

  getBasicTechniques() {
    return (
      <div className="box">
        <hr />
        <h2 className="intro-text text-center" style={{ fontWeight: "bold" }}>
          Basic Techniques
                </h2>
        <div className="row">
          <div className="col-12">
            <Video title="Basic Blocks" subtitle="Mirror Image" url="https://www.youtube.com/embed/sBkBDCRbZQI" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Front Snap Kick"
              url="https://www.youtube.com/embed/QIiGZ73mmqA"
              techniques={(null)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Front Snap Kick - Variation"
              url="https://www.youtube.com/embed/NOwZX-O1i8Q"
              techniques={(null)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Roundhouse Kick"
              url="https://www.youtube.com/embed/a3h-DCrHwVQ"
              techniques={(null)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Axe Kick"
              url="https://www.youtube.com/embed/-q3CtMb0WXw"
              techniques={(null)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Side Kick"
              url="https://www.youtube.com/embed/kKrKbhARDOw"
              techniques={(null)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Back Kick"
              url="https://www.youtube.com/embed/jW12t-jkkwU"
              techniques={(null)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Back Hook Kick"
              url="https://www.youtube.com/embed/ucQh6d9fOl0"
              techniques={(null)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Walking and Skipping"
              url="https://www.youtube.com/embed/Ok8XjXdb-ok"
              techniques={(null)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Turning"
              url="https://www.youtube.com/embed/NbXKT_t5CIM"
              techniques={(null)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Jumping"
              url="https://www.youtube.com/embed/ZmEFcjimeP8"
              techniques={(null)}
            />
          </div>
        </div>
      </div>
    );
  }

  getBasicMotion() {
    return (
      <div className="box">
        <hr />
        <h2 className="intro-text text-center" style={{ fontWeight: "bold" }}>
          Basic Motion
                </h2>
        <div className="row">
          <div className="col-12">
            <Video
              title="White Belt 1-10"
              url="https://www.youtube.com/embed/9d1Rr79tg9I"
              techniques={(
                <div>
                  The student begins at the charyot position of attention.
                  <ul>
                    <li>Move the left foot out one shoulder-length</li>
                    <li>Bend both elbows to bring the fists one fist-length away from each other and the chest</li>
                    <li>Lower the fists to the junbi position</li>
                    <li>Move the left foot out into the horseback riding stance.</li>
                    <li>While moving the left leg, bring each fist to the side of the body next to the hips</li>
                    <li>Middle punch with the left fist to the solar plexus</li>
                    <li>Bring the left fist back and punch with the right fist</li>
                    <li>Bring both fists back to the same position as step 2</li>
                    <li>Bring the left foot back in to one shoulder-width apart</li>
                    <li>Lower the fists back to the same position as step 3</li>
                    <li>Return to charyot position</li>
                  </ul>
                                    When complete, the student bows and says "Thank you sir" or "Thank you ma'am"
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Yellow Belt 11-20"
              url="https://www.youtube.com/embed/W2_vBWvTX1I"
              techniques={(
                <div>
                  Basic Motion 11-20 continues from charyot after 1-10. It focuses on blocks
                  <ul>
                    <li>Move to horseback riding stance and execute a low block to the left</li>
                    <li>Execute a low block to the right</li>
                    <li>Execute an inner block with the left arm</li>
                    <li>Execute an inner block with the right arm</li>
                    <li>Execute an outer black with the left arm</li>
                    <li>Execute an outer back with the right arm</li>
                    <li>Execute a high block with the left arm</li>
                    <li>Execute a high block with the right arm</li>
                    <li>Execute a middle punch with the left arm</li>
                    <li>Execute a middle punch with the right arm</li>
                  </ul>
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Orange Belt 21-30"
              url="https://www.youtube.com/embed/VQ173mJMMNY"
              techniques={(
                <div>
                  <ul>
                    <li>Move to horseback riding stance and execute a high punch with the left hand, targeting the nose</li>
                    <li>Execute a high punch with the right hand, targeting the nose</li>
                    <li>Execute a low punch with the left hand</li>
                    <li>Execute a low punch with the right hand</li>
                    <li>Execute an uppercut with the left hand, targeting the chin</li>
                    <li>Execute an uppercut with the right hand, targeting the chin</li>
                    <li>Execute a hook punch with the left hand, targeting the jawline</li>
                    <li>Execute a hook punch with the right hand, targeting the jawline</li>
                    <li>Turn to the right in a right forward stance and execute a reverse middle punch</li>
                    <li>Turn to your rear in a left forward stance and execute a downward punch with the back hand </li>
                  </ul>
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Green Belt 31-40"
              url="https://www.youtube.com/embed/M0UOo_uNVsY"
              techniques={(
                <div>
                  <ul>
                    <li>From horseback riding stance, execute a double knifehand technique to break the grab to the shoulders from an attacker</li>
                    <li>Execute a left hand outer knifehand strike to the neck</li>
                    <li>Execute a left hand inner knifehand strike to the next</li>
                    <li>Chamber the left hand for a fingertip strike at the left hip and cover it with the right hand</li>
                    <li>Execute a left hand fingertip strike to the solar plexus</li>
                    <li>Execute a right hand outer knifehand strike to the neck</li>
                    <li>Execute a right hand inner knifehand strike to the next</li>
                    <li>Chamber the right hand for a fingertip strike at the left hip and cover it with the left hand</li>
                    <li>Execute a right hand fingertip strike to the solar plexus</li>
                    <li>Bring the feat to shoulder-width apart and execute a double down side block</li>
                  </ul>
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Blue Belt 41-50"
              url="https://www.youtube.com/embed/X6VXiF4Rhzc"
              techniques={(
                <div>
                  <ul>
                    <li>Go to horseback riding stance and execute an upward elbow strike with the front of the left elbow, targeting the chin or jawline</li>
                    <li>Execute an upward elbow strike with the front of the right elbow, targeting the chin or jawline</li>
                    <li>Execute an elbow strike with the front of the left elbow, targeting the chin or jawline</li>
                    <li>Execute an elbow strike with the front of the right elbow, targeting the chin or jawline</li>
                    <li>Execute an elbow strike with the back of the left elbow for an outward strike, targeting the chin or jawline</li>
                    <li>Execute an elbow strike with the back of the right elbow for an outward strike, targeting the chin or jawline</li>
                    <li>Execute an elbow strike across the head with the back of the left elbow</li>
                    <li>Execute an elbow strike across the head with the back of the right elbow</li>
                    <li>Turn to the right into a right forward stance and execute an upward elbow strike with the back of the  left elbow</li>
                    <li>Turn to the left into a left forward stance and execute a back hand downward elbow strike</li>
                  </ul>
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Purple Belt 51-60"
              url="https://www.youtube.com/embed/TMpNdzdv6FY"
              techniques={(
                <div>
                  <ul>
                    <li>Starting in a left forward stance, execute a double downward knifehand block to the sides</li>
                    <li>Execute a front snap kick then a jab then a reverse punch</li>
                    <li>Turn to the back and execute a double downward knifehand block to the sides</li>
                    <li>Execute a front snap kick then a jab then a reverse punch</li>
                    <li>Move the left foot to the front into a left forward stance and execute a simultaneous left hand high knifehand block and right knifehand neck strike</li>
                    <li>Move the left foot back into a right forward stance and execute a simultaneous right hand high knifehand block and left knifehand neck strike</li>
                    <li>Move the left foot into a crane stance, placing it beside the right knee and execute a simultaneous left hand downward side block and right hand high block</li>
                    <li>Move the right foot into a crane stance, placing it beside the left knee and execute a simultaneous right hand downward side block and left hand high block</li>
                    <li>Move into a horseback riding stance and chamber each hand on each hip, hands open facing up</li>
                    <li>Move the feet together and execute a double block in the front with hands open, facing down, and left hand on top</li>
                  </ul>
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Red Belt 61-70"
              url="https://www.youtube.com/embed/QYj3OGEB6cc"
              techniques={(
                <div>
                  <ul>
                    <li>Turn to the left into a right back stance (left foot will be forward) and execute a double middle knifehand block</li>
                    <li>Execute a double downward knifehand block</li>
                    <li>Turn to the back, lifting up the right foot and transition into a left back stance and execute a double middle knifehand block</li>
                    <li>Execute a double downward knifehand block</li>
                    <li>Turn to the back, lifting up the left foot and transition into a right back stance and execute a double middle block with the left hand facing in and both hands closed</li>
                    <li>Turn to the back, lifting up the right foot and transition into a left back stance and execute a double middle block with the right hand facing in and both hands closed</li>
                    <li>Turn to the front and bring the left foot into a crane stance, reaching forward with the left hand, and chambering for a backfist strike with the right hand; place the left foot on the ground into a horseback riding stance and execute the backfist with the right hand to the nose, support the elbow with the closed left hand</li>
                    <li>Bring the right foot into a crane stance, reaching forward with the right  hand, and chambering for a backfist strike with the left hand to the nose; place the right foot on the ground into a horseback riding stance and execute the backfist with the left hand, support the elbow with the closed right hand</li>
                    <li>Chamber for and execute a double down cross block</li>
                    <li>Move the feet together and execute a double up cross block</li>
                  </ul>
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="High Red Belt 71-80"
              url="https://www.youtube.com/embed/LfqjC04BEY0"
              techniques={(<strong>Coming Soon!</strong>)} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Brown Belt 81-90"
              url="https://www.youtube.com/embed/GJGhcwpd2Gk"
              techniques={(<strong>Coming Soon!</strong>)} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="High Brown Belt 91-100"
              url="https://www.youtube.com/embed/fEA_v94tGhU"
              techniques={(<strong>Coming Soon!</strong>)} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Deputy Black Belt 101-110"
              url="https://www.youtube.com/embed/FlQWJ1qEoes"
              techniques={(<strong>Coming Soon!</strong>)} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="1 Dan 111-130"
              url="https://www.youtube.com/embed/eiSm4JVE1tA"
              techniques={(<strong>Coming Soon!</strong>)} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="2 Dan 131-150"
              url="https://www.youtube.com/embed/QFZ-qNyhW8U"
              techniques={(<strong>Coming Soon!</strong>)} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="3 Dan 151-170"
              url="https://www.youtube.com/embed/vFiV8aYrjHQ"
              techniques={(<strong>Coming Soon!</strong>)} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="4 Dan 171-200"
              url="https://www.youtube.com/embed/NaVt8x0JuNc"
              techniques={(<strong>Coming Soon!</strong>)} />
          </div>
        </div>
      </div>
    );
  }

  getSabanchuk() {
    return (
      <div className="box">
        <hr />
        <h2 className="intro-text text-center" style={{ fontWeight: "bold" }}>
          Sabanchuk / 4 Direction Kick
                </h2>
        <div className="row">
          <div className="col-12">
            <Video
              title="White Belt - All"
              url="https://www.youtube.com/embed/mFin46mOztk"
              techniques={(
                <div>
                  <p>Sabangchuk is the term given for a "four direction kick" set of techniques. Each level will have its own sabangchuk</p>

                  <p>The first sabangchuk assists in orienting the student to the basic kicking directions used in Taekwondo.</p>

                  <p>The student begins in the balchaji junbi (kicking stance). The student then executes the following in order while announcing each technique:</p>

                  <ul>
                    <li>a front snap kick with their right leg and switches legs</li>
                    <li>a front snap kick with their left leg and switches legs</li>
                    <li>a side kick with the right leg to the right of the student</li>
                    <li>a side kick with the left leg to the left of the student</li>
                    <li>a back kick to the rear of the student with the right leg</li>
                    <li>a back kick to the rear of the student with the left leg</li>
                    <li>Without putting the left leg down, the student enters into a forward stance while simultaneously executing a high block with the left arm, a middle punch with the right arm, and yells "Kiap".</li>
                  </ul>
                </div>)} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Yellow Belt - All"
              url="https://www.youtube.com/embed/mAc0gryyBWg"
              techniques={(<div>
                <p>Sabangchuk number 2 introduces the concepts of kicking in directions and turning. The student will begin in the kicking stance. Upon beginning, they will execute the following techniques while announcing each technique:</p>

                <ul>
                  <li>a front snap kick</li>
                  <li>a round-house kick</li>
                  <li>a middle punch</li>
                  <li>a turn</li>
                </ul>

                <p>This is one direction.</p>

                <p>Yellow belts complete four directions regardless of age.</p>

                <ul>
                  <li>North</li>
                  <li>South</li>
                  <li>West</li>
                  <li>East</li>
                </ul>
              </div>)} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Orange Belt - Little Tigers and Juniors"
              url="https://www.youtube.com/embed/pRdhY69fqqk"
              techniques={(
                <div>
                  <p>The student will begin in the kicking stance. Upon beginning, they will execute </p>

                  <ul>
                    <li>an axe kick</li>
                    <li>a round-house kick</li>
                    <li>a side kick</li>
                    <li>a middle punch </li>
                    <li>a turn</li>
                  </ul>

                  <p>This is one direction.</p>

                  <p>For adults, the student will perform the maneuvers in eight distinct directions. Assuming a northern start, the directions will be, in order,</p>

                  <ul>
                    <li>North</li>
                    <li>South</li>
                    <li>West</li>
                    <li>East</li>
                    <li>South</li>
                    <li>North</li>
                    <li>West</li>
                    <li>East</li>
                  </ul>

                  <p>A child will only complete the first four directions.  </p>
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Orange Belt - Teen / Adult"
              url="https://www.youtube.com/embed/Wmm6nCnRIb0"
              techniques={(
                <div>
                  <p>The student will begin in the kicking stance. Upon beginning, they will execute </p>

                  <ul>
                    <li>an axe kick</li>
                    <li>a round-house kick</li>
                    <li>a side kick</li>
                    <li>a middle punch </li>
                    <li>a turn</li>
                  </ul>

                  <p>This is one direction.</p>

                  <p>For adults, the student will perform the maneuvers in eight distinct directions. Assuming a northern start, the directions will be, in order,</p>

                  <ul>
                    <li>North</li>
                    <li>South</li>
                    <li>West</li>
                    <li>East</li>
                    <li>South</li>
                    <li>North</li>
                    <li>West</li>
                    <li>East</li>
                  </ul>

                  <p>A child will only complete the first four directions.  </p>
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Green Belt - Little Tigers and Juniors"
              url="https://www.youtube.com/embed/LR7M-vp0Mmc"
              techniques={(
                <div>
                  <p>The student will begin in the kicking stance. Upon beginning, they will execute </p>

                  <ul>
                    <li>a round-house kick</li>
                    <li>a back kick </li>
                    <li>a middle punch </li>
                    <li>a turn</li>
                  </ul>

                  <p>This is one direction.</p>

                  <p>For adults, the student will perform the maneuvers in eight distinct directions. Assuming a northern start, the directions will be, in order,</p>

                  <ul>
                    <li>North</li>
                    <li>South</li>
                    <li>West</li>
                    <li>East</li>
                    <li>South</li>
                    <li>North</li>
                    <li>West</li>
                    <li>East</li>
                  </ul>

                  <p>A child will only complete the first four directions.  </p>
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Green Belt - Teen / Adult"
              url="https://www.youtube.com/embed/pigzZzxHy4k"
              techniques={(
                <div>
                  <p>The student will begin in the kicking stance. Upon beginning, they will execute </p>

                  <ul>
                    <li>a round-house kick</li>
                    <li>a back kick kick </li>
                    <li>a middle punch </li>
                    <li>a turn</li>
                  </ul>

                  <p>This is one direction.</p>

                  <p>For adults, the student will perform the maneuvers in eight distinct directions. Assuming a northern start, the directions will be, in order,</p>

                  <ul>
                    <li>North</li>
                    <li>South</li>
                    <li>West</li>
                    <li>East</li>
                    <li>South</li>
                    <li>North</li>
                    <li>West</li>
                    <li>East</li>
                  </ul>

                  <p>A child will only complete the first four directions.  </p>
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Blue Belt - Little Tigers and Juniors"
              url="https://www.youtube.com/embed/yj3lmqV86nA"
              techniques={(
                <div>
                  <p>The student will begin in the kicking stance. Starting with Sabangchuk 5, the student does not need to announce each movement. Upon beginning, they will execute </p>

                  <ul>
                    <li>a double round-house kick targeting low (approximately the knee) and then high (the head)</li>
                    <li>a double side-kick targeting low (approximately the knee) and then high (the head)</li>
                    <li>a middle punch</li>
                    <li>a turn</li>
                  </ul>

                  <p>This is one direction.</p>

                  <p>For adults, the student will perform the maneuvers in eight distinct directions. Assuming a northern start, the directions will be, in order,</p>

                  <ul>
                    <li>North</li>
                    <li>South</li>
                    <li>West</li>
                    <li>East</li>
                    <li>South</li>
                    <li>North</li>
                    <li>West</li>
                    <li>East</li>
                  </ul>

                  <p>A child will only complete the first four directions.  </p>
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Blue Belt - Teen / Adult"
              url="https://www.youtube.com/embed/sdrLPfa3HzA"
              techniques={(
                <div>
                  <p>The student will begin in the kicking stance. Starting with Sabangchuk 5, the student does not need to announce each movement. Upon beginning, they will execute </p>

                  <ul>
                    <li>a double round-house kick targeting low and then high</li>
                    <li>a double side-kick targeting low and then high</li>
                    <li>a middle punch</li>
                    <li>a turn</li>
                  </ul>

                  <p>This is one direction.</p>

                  <p>For adults, the student will perform the maneuvers in eight distinct directions. Assuming a northern start, the directions will be, in order,</p>

                  <ul>
                    <li>North</li>
                    <li>South</li>
                    <li>West</li>
                    <li>East</li>
                    <li>South</li>
                    <li>North</li>
                    <li>West</li>
                    <li>East</li>
                  </ul>

                  <p>A child will only complete the first four directions.  </p>
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Purple Belt - Little Tigers and Juniors"
              url="https://www.youtube.com/embed/q2ZEsmeORf8"
              techniques={(
                <div>
                  <p>The student will begin in the kicking stance. Upon beginning, they will execute </p>

                  <ul>
                    <li>a skipping round-house kick</li>
                    <li>a round-house kick </li>
                    <li>a back-hook kick</li>
                    <li>a middle punch </li>
                    <li>a turn </li>
                  </ul>

                  <p>This is one direction.</p>

                  <p>For adults, the student will perform the maneuvers in eight distinct directions. Assuming a northern start, the directions will be, in order,</p>

                  <ul>
                    <li>North</li>
                    <li>South</li>
                    <li>West</li>
                    <li>East</li>
                    <li>South</li>
                    <li>North</li>
                    <li>West</li>
                    <li>East</li>
                  </ul>

                  <p>A child will only complete the first four directions.  </p>
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Purple Belt - Teen / Adult"
              url="https://www.youtube.com/embed/-aySEaSO1jU"
              techniques={(
                <div>
                  <p>The student will begin in the kicking stance. Upon beginning, they will execute </p>

                  <ul>
                    <li>a skipping round-house kick</li>
                    <li>a round-house kick </li>
                    <li>a back-hook kick</li>
                    <li>a middle punch </li>
                    <li>a turn </li>
                  </ul>

                  <p>This is one direction.</p>

                  <p>For adults, the student will perform the maneuvers in eight distinct directions. Assuming a northern start, the directions will be, in order,</p>

                  <ul>
                    <li>North</li>
                    <li>South</li>
                    <li>West</li>
                    <li>East</li>
                    <li>South</li>
                    <li>North</li>
                    <li>West</li>
                    <li>East</li>
                  </ul>

                  <p>A child will only complete the first four directions.  </p>
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Red Belt - Little Tigers and Juniors"
              url="https://www.youtube.com/embed/1U24Gt2KoVE"
              techniques={(
                <div>
                  <p>The student will begin in the kicking stance. Upon beginning, they will execute </p>

                  <ul>
                    <li>a skipping round-house kick</li>
                    <li>a turning round-house kick </li>
                    <li>a middle punch </li>
                    <li>a turn</li>
                  </ul>

                  <p>This is one direction.</p>

                  <p>For adults, the student will perform the maneuvers in eight distinct directions. Assuming a northern start, the directions will be, in order,</p>

                  <ul>
                    <li>North</li>
                    <li>South</li>
                    <li>West</li>
                    <li>East</li>
                    <li>South</li>
                    <li>North</li>
                    <li>West</li>
                    <li>East</li>
                  </ul>

                  <p>A child will only complete the first four directions.  </p>
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Red Belt - Teen / Adult"
              url="https://www.youtube.com/embed/xRIzugPzKxI"
              techniques={(
                <div>
                  <p>The student will begin in the kicking stance. Upon beginning, they will execute </p>

                  <ul>
                    <li>a skipping round-house kick</li>
                    <li>a turning round-house kick </li>
                    <li>a middle punch </li>
                    <li>a turn</li>
                  </ul>

                  <p>This is one direction.</p>

                  <p>For adults, the student will perform the maneuvers in eight distinct directions. Assuming a northern start, the directions will be, in order,</p>

                  <ul>
                    <li>North</li>
                    <li>South</li>
                    <li>West</li>
                    <li>East</li>
                    <li>South</li>
                    <li>North</li>
                    <li>West</li>
                    <li>East</li>
                  </ul>

                  <p>A child will only complete the first four directions.  </p>
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="High Red Belt - Little Tigers and Juniors"
              url="https://www.youtube.com/embed/_aZJPGyLBpU"
              techniques={(
                <div>
                  <p>The student will begin in the kicking stance. Upon beginning, they will execute </p>

                  <ul>
                    <li>a skipping round-house kick</li>
                    <li>a round-house kick </li>
                    <li>a turning round-house kick</li>
                    <li>a middle punch</li>
                    <li>a turn</li>
                  </ul>

                  <p>This is one direction.</p>

                  <p>For adults, the student will perform the maneuvers in eight distinct directions. Assuming a northern start, the directions will be, in order,</p>

                  <ul>
                    <li>North</li>
                    <li>South</li>
                    <li>West</li>
                    <li>East</li>
                    <li>South</li>
                    <li>North</li>
                    <li>West</li>
                    <li>East</li>
                  </ul>

                  <p>A child will only complete the first four directions.  </p>
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="High Red Belt - Teen / Adult"
              url="https://www.youtube.com/embed/sqjj5D8bp5Q"
              techniques={(
                <div>
                  <p>The student will begin in the kicking stance. Upon beginning, they will execute </p>

                  <ul>
                    <li>a skipping round-house kick</li>
                    <li>a round-house kick </li>
                    <li>a turning round-house kick</li>
                    <li>a middle punch</li>
                    <li>a turn</li>
                  </ul>

                  <p>This is one direction.</p>

                  <p>For adults, the student will perform the maneuvers in eight distinct directions. Assuming a northern start, the directions will be, in order,</p>

                  <ul>
                    <li>North</li>
                    <li>South</li>
                    <li>West</li>
                    <li>East</li>
                    <li>South</li>
                    <li>North</li>
                    <li>West</li>
                    <li>East</li>
                  </ul>

                  <p>A child will only complete the first four directions.  </p>
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Brown Belt - Little Tigers and Juniors"
              url="https://www.youtube.com/embed/cWMJIFTAmno"
              techniques={(
                <div>
                  <p>The student will begin in the kicking stance. Upon beginning, they will execute </p>

                  <ul>
                    <li>a jumping front-snap kick</li>
                    <li>a jumping round-house kick </li>
                    <li>a turn</li>
                  </ul>

                  <p>This is one direction. Notice there is no punch at the end of the direction. You will only punch at the conclusion of the entire sabanchuk. </p>

                  <p>For adults, the student will perform the maneuvers in eight distinct directions. Assuming a northern start, the directions will be, in order,</p>

                  <ul>
                    <li>North</li>
                    <li>South</li>
                    <li>West</li>
                    <li>East</li>
                    <li>South</li>
                    <li>North</li>
                    <li>West</li>
                    <li>East</li>
                  </ul>

                  <p>A child will only complete the first four directions.  </p>
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Brown Belt - Teen / Adult"
              url="https://www.youtube.com/embed/uOdnEiQXySc"
              techniques={(
                <div>
                  <p>The student will begin in the kicking stance. Upon beginning, they will execute </p>

                  <ul>
                    <li>a jumping front-snap kick</li>
                    <li>a jumping round-house kick </li>
                    <li>a turn</li>
                  </ul>

                  <p>This is one direction. Notice there is no punch at the end of the direction. You will only punch at the conclusion of the entire sabanchuk. </p>

                  <p>For adults, the student will perform the maneuvers in eight distinct directions. Assuming a northern start, the directions will be, in order,</p>

                  <ul>
                    <li>North</li>
                    <li>South</li>
                    <li>West</li>
                    <li>East</li>
                    <li>South</li>
                    <li>North</li>
                    <li>West</li>
                    <li>East</li>
                  </ul>

                  <p>A child will only complete the first four directions.  </p>
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="High Brown Belt - Little Tigers and Juniors"
              url="https://www.youtube.com/embed/1bt-ko6QKUo"
              techniques={(
                <div>
                  <p>The student will begin in the kicking stance. Upon beginning, they will execute </p>

                  <ul>
                    <li>a jumping roundhouse kick</li>
                    <li>a jumping back kick </li>
                    <li>a turn</li>
                  </ul>

                  <p>This is one direction. Notice there is no punch at the end of the direction. You will only punch at the conclusion of the entire sabanchuk. </p>

                  <p>For adults, the student will perform the maneuvers in eight distinct directions. Assuming a northern start, the directions will be, in order,</p>

                  <ul>
                    <li>North</li>
                    <li>South</li>
                    <li>West</li>
                    <li>East</li>
                    <li>South</li>
                    <li>North</li>
                    <li>West</li>
                    <li>East</li>
                  </ul>

                  <p>A child will only complete the first four directions.  </p>
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="High Brown Belt - Teen / Adult"
              url="https://www.youtube.com/embed/30l8jxeBGDk"
              techniques={(
                <div>
                  <p>The student will begin in the kicking stance. Upon beginning, they will execute </p>

                  <ul>
                    <li>a jumping roundhouse kick</li>
                    <li>a jumping back kick </li>
                    <li>a turn</li>
                  </ul>

                  <p>This is one direction. Notice there is no punch at the end of the direction. You will only punch at the conclusion of the entire sabanchuk. </p>

                  <p>For adults, the student will perform the maneuvers in eight distinct directions. Assuming a northern start, the directions will be, in order,</p>

                  <ul>
                    <li>North</li>
                    <li>South</li>
                    <li>West</li>
                    <li>East</li>
                    <li>South</li>
                    <li>North</li>
                    <li>West</li>
                    <li>East</li>
                  </ul>

                  <p>A child will only complete the first four directions.  </p>
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Deputy Black Belt - All"
              url="https://www.youtube.com/embed/X_7fePbJvYA"
              techniques={(
                <div>
                  <p>The student will begin in the kicking stance. Upon beginning, they will execute </p>

                  <ul>
                    <li>a back kick</li>
                    <li>a back kick</li>
                    <li>a jumping-back round-house kick</li>
                    <li>a jumping-back round-house kick</li>
                    <li>a punch</li>
                    <li>a turn</li>
                  </ul>

                  <p>This is one direction.</p>

                  <p>The student will perform the maneuvers in eight distinct directions. Assuming a northern start, the directions will be, in order,</p>

                  <ul>
                    <li>North</li>
                    <li>South</li>
                    <li>West</li>
                    <li>East</li>
                    <li>South</li>
                    <li>North</li>
                    <li>West</li>
                    <li>East</li>
                  </ul>

                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="1 Dan A - Under 30 Years Old"
              url="https://www.youtube.com/embed/fHhiUr9kesA"
              techniques={(
                <div>
                  <p>The student will begin in the kicking stance. The student will execute each of the previous sabanchuks in two directions each. Then, they will execute the following new techniques: </p>

                  <ul>
                    <li>Round-house and back-hook kick in the air</li>
                    <li>High jab and high reverse punch</li>
                  </ul>

                  <p>This is one direction.</p>

                  <p>The student will perform the maneuvers in eight distinct directions. Assuming a northern start, the directions will be, in order,</p>

                  <ul>
                    <li>North</li>
                    <li>South</li>
                    <li>West</li>
                    <li>East</li>
                    <li>South</li>
                    <li>North</li>
                    <li>West</li>
                    <li>East</li>
                  </ul>

                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="1 Dan B - 30 Years Old or Older"
              url="https://www.youtube.com/embed/OL5ruOYs4E0"
              techniques={(
                <div>
                  <p>The student will begin in the kicking stance. The student will execute each of the previous sabanchuks in two directions each. Then, they will execute the following new techniques: </p>

                  <ul>
                    <li>Front snap kick</li>
                    <li>skipping front kick</li>
                    <li>round-house kick</li>
                    <li>back-hook kick</li>
                    <li>front hand high backfist</li>
                    <li>turn to your back and high backfist</li>
                  </ul>

                  <p>This is one direction.</p>

                  <p>The student will perform the maneuvers in eight distinct directions. Assuming a northern start, the directions will be, in order,</p>

                  <ul>
                    <li>North</li>
                    <li>South</li>
                    <li>West</li>
                    <li>East</li>
                    <li>South</li>
                    <li>North</li>
                    <li>West</li>
                    <li>East</li>
                  </ul>
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="2 Dan"
              url="https://www.youtube.com/embed/Or85sV5HD0A"
              techniques={(
                <div>
                  <p>The student will begin in the kicking stance. The student will execute each of the previous sabanchuks in two directions each. Then, they will execute the following new techniques: (note that there are three possible combinations for the first kicks; the video demonstrates "ee bang", which is two targets in the air before landing) </p>

                  <ul>
                    <li>Double front snap kick</li>
                    <li>Round house kick</li>
                    <li>Back kick</li>
                    <li>Fronthand outer kinfehand strike</li>
                    <li>Backhand knifehand strike</li>
                    <li>Turn</li>
                    <li>Double front snap kick</li>
                    <li>Round house kick</li>
                    <li>Back kick</li>
                    <li>Fronthand outer kinfehand strike</li>
                    <li>Backhand knifehand strike</li>
                    <li>Turn</li>
                    <li>High round house kick</li>
                    <li>Leg sweep</li>
                    <li>Front hand down punch on the ground</li>
                    <li>Back hand down punch on the ground</li>
                    <li>Stand and turn</li>
                    <li>High round house kick</li>
                    <li>Leg sweep</li>
                    <li>Front hand down punch on the ground</li>
                    <li>Back hand down punch on the ground</li>
                    <li>Stand and turn</li>
                    <li>Double front snap kick</li>
                    <li>Round house kick</li>
                    <li>Back kick</li>
                    <li>Fronthand outer kinfehand strike</li>
                    <li>Backhand knifehand strike</li>
                    <li>Turn</li>
                    <li>Double front snap kick</li>
                    <li>Round house kick</li>
                    <li>Back kick</li>
                    <li>Fronthand outer kinfehand strike</li>
                    <li>Backhand knifehand strike</li>
                    <li>Turn</li>
                    <li>High round house kick</li>
                    <li>Leg sweep</li>
                    <li>Front hand down punch on the ground</li>
                    <li>Back hand down punch on the ground</li>
                    <li>Stand and turn</li>
                    <li>High round house kick</li>
                    <li>Leg sweep</li>
                    <li>Front hand down punch on the ground</li>
                    <li>Back hand down punch on the ground</li>
                    <li>Turn and front hand down punch on the ground</li>
                    <li>Back hand down punch on the ground and jihap</li>
                  </ul>

                  <p>The student will perform the maneuvers in eight distinct directions. Assuming a northern start, the directions will be, in order,</p>

                  <ul>
                    <li>North</li>
                    <li>South</li>
                    <li>West</li>
                    <li>East</li>
                    <li>South</li>
                    <li>North</li>
                    <li>West</li>
                    <li>East</li>
                  </ul>

                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="3 Dan"
              url="https://www.youtube.com/embed/A1YEBrsfnBI"
              techniques={(
                <div>
                  <p>The student will begin in the kicking stance. Upon beginning, they will execute </p>

                  <ul>
                    <li>Middle Front Snap Kick</li>
                    <li>High Front Snap Kick</li>
                    <li>Front hand jab into backfist</li>
                    <li>Backhand crossing punch</li>
                    <li>Turn</li>
                    <li>Middle Front Snap Kick</li>
                    <li>High Roundhouse Kick</li>
                    <li>Front hand jab into backfist</li>
                    <li>Backhand crossing punch</li>
                    <li>Turn</li>
                    <li>Middle Front Snap Kick</li>
                    <li>Side Kick</li>
                    <li>Front hand jab into backfist</li>
                    <li>Backhand crossing punch</li>
                    <li>Turn</li>
                    <li>Middle Front Snap Kick</li>
                    <li>Back Kick</li>
                    <li>Front hand jab into backfist</li>
                    <li>Backhand crossing punch</li>
                    <li>Turn</li>
                  </ul>
                  <p>Now we do the opposite of the kicks for the final four directions</p>
                  <ul>
                    <li>High Front Snap Kick</li>
                    <li>Middle Front Snap Kick</li>
                    <li>Front hand jab into backfist</li>
                    <li>Backhand crossing punch</li>
                    <li>Turn</li>
                    <li>High Front Snap Kick</li>
                    <li>Middle Roundhouse Kick</li>
                    <li>Front hand jab into backfist</li>
                    <li>Backhand crossing punch</li>
                    <li>Turn</li>
                    <li>Side Kick</li>
                    <li>Middle Front Snap Kick</li>
                    <li>Front hand jab into backfist</li>
                    <li>Backhand crossing punch</li>
                    <li>Turn</li>
                    <li>Back Kit</li>
                    <li>Middle Front Snap Kick</li>
                    <li>Front hand jab into backfist</li>
                    <li>Backhand crossing punch</li>
                    <li>Turn</li>
                    <li>Front hand jab into backfist</li>
                    <li>Backhand crossing punch and Kihap</li>
                  </ul>

                  <p>This is one direction.</p>

                  <p>The student will perform the maneuvers in eight distinct directions. Assuming a northern start, the directions will be, in order,</p>

                  <ul>
                    <li>North</li>
                    <li>South</li>
                    <li>West</li>
                    <li>East</li>
                    <li>South</li>
                    <li>North</li>
                    <li>West</li>
                    <li>East</li>
                  </ul>

                </div>
              )} />
          </div>
        </div>
      </div>
    );
  }

  getForms() {
    return (
      <div className="box">
        <hr />
        <h2 className="intro-text text-center" style={{ fontWeight: "bold" }}>
          Basic Forms
                </h2>
        <div className="row">
          <div className="col-12">
            <Video
              title="White Belt - Basic Form 1 - Juniors"
              url="https://www.youtube.com/embed/Gx74Res4T4o"
              techniques={(null)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="White Belt - Basic Form 1 - Teens and Adults"
              url="https://www.youtube.com/embed/Ty9VQzOCoY8"
              techniques={(null)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Yellow Belt - Basic Form 2 - Juniors"
              url="https://www.youtube.com/embed/F8mcr3vZxP0"
              techniques={(null)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Yellow Belt - Basic Form 2 - Teens and Adults"
              url="https://www.youtube.com/embed/UuIdDbGumd0"
              techniques={(null)}
            />
          </div>
        </div>
      </div>
    );
  }

  getPoomsaes() {
    return (
      <div className="box">
        <hr />
        <h2 className="intro-text text-center" style={{ fontWeight: "bold" }}>
          Poomsaes
                </h2>
        <div className="row">
          <div className="col-12">
            <Video
              title="Orange Belt - Taegeuk Il Jang"
              url="https://www.youtube.com/embed/NzalD6Jev5M"
              techniques={(null)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Green Belt - Taegeuk Ee Jang"
              url="https://www.youtube.com/embed/FmoqPRg6VHM"
              techniques={(null)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Blue Belt - Taegeuk Sam Jang"
              url="https://www.youtube.com/embed/RtJTD54xJoM"
              techniques={(null)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Purple Belt - Taegeuk Sa Jang"
              url="https://www.youtube.com/embed/o39eQGtSo34"
              techniques={(null)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Red Belt - Taegeuk Oh Jang"
              url="https://www.youtube.com/embed/yyXdTxf8vzc"
              techniques={(null)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="High Red Belt - Taegeuk Yuk Jang"
              url="https://www.youtube.com/embed/lUICFkQnvSI"
              techniques={(null)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Brown Belt - Taegeuk Chil Jang"
              url="https://www.youtube.com/embed/v5Oqh_YXy_I"
              techniques={(null)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="High Brown Belt - Taegeuk Pal Jang"
              url="https://www.youtube.com/embed/SBDU0QbGmaA"
              techniques={(null)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="1 Dan - Koryo"
              url="https://www.youtube.com/embed/0fQAhlOLpuQ"
              techniques={(null)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="2 Dan - Keumkang"
              url="https://www.youtube.com/embed/aRsuRV9byPc"
              techniques={(null)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="3 Dan - Taebaek"
              url="https://www.youtube.com/embed/EoxUrY3lrqE"
              techniques={(null)}
            />
          </div>
        </div>
      </div>
    );
  }

  getTargetKicking() {
    return (
      <div className="box">
        <hr />
        <h2 className="intro-text text-center" style={{ fontWeight: "bold" }}>
          Target Kicking - Little Tigers
                </h2>
        <div className="row">
          <div className="col-12">
            <Video title="White Belt" url="https://www.youtube.com/embed/8Gyb6SXflvI" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video title="Yellow Belt" url="https://www.youtube.com/embed/boN-Wi53igo" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video title="Orange Belt" url="https://www.youtube.com/embed/LatOXygAuag" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video title="Green Belt" url="https://www.youtube.com/embed/tMGt6RW1HBk" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video title="Blue Belt" url="https://www.youtube.com/embed/diMid5SJCkc" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video title="Purple Belt" url="https://www.youtube.com/embed/fxC59Jj4Nj0" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video title="Red Belt" url="https://www.youtube.com/embed/GA_nZ1x2nIg" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video title="High Red Belt" url="https://www.youtube.com/embed/e6H8CPytL2Q" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video title="Brown Belt" url="https://www.youtube.com/embed/resoBQiHU58" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video title="High Brown Belt" url="https://www.youtube.com/embed/4e8kAIdMnk8" />
          </div>
        </div>
      </div>
    );
  }

  getBreaking() {
    return (
      <div className="box">
        <hr />
        <h2 className="intro-text text-center" style={{ fontWeight: "bold" }}>
          Breaking Techniques
                </h2>
        <div className="row">
          <div className="col-12">
            <Video
              title="White Belt Little Tigers and Juniors"
              url="https://www.youtube.com/embed/ZqGjX3e2D4A"
              techniques={(
                <div>
                  The breaking technique is Hammer Fist
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="White Belt Teen / Adult"
              url="https://www.youtube.com/embed/QPw4_O34Atg"
              techniques={(
                <div>
                  The breaking technique is Straight Punch
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Yellow Belt Little Tigers and Juniors"
              url="https://www.youtube.com/embed/jAV2DQE7ZQM"
              techniques={(
                <div>
                  The breaking technique is Knife Hand
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Yellow Belt Teen / Adult"
              url="https://www.youtube.com/embed/afPKTixm_RA"
              techniques={(
                <div>
                  The breaking technique is Ridge Hand
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Orange Belt Little Tigers and Juniors"
              url="https://www.youtube.com/embed/Oxj2oIe3LOU"
              techniques={(
                <div>
                  The breaking technique is Front Snap Kick
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Orange Belt Teen / Adult"
              url="https://www.youtube.com/embed/EnuxoYtdUA0"
              techniques={(
                <div>
                  The breaking technique is Roundhouse Kick
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Green Belt Little Tigers and Juniors"
              url="https://www.youtube.com/embed/9RLNeD1-FAk"
              techniques={(
                <div>
                  The breaking technique is Axe Kick
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Green Belt Teen / Adult"
              url="https://www.youtube.com/embed/FMf8r35aCSM"
              techniques={(
                <div>
                  The breaking technique is Back Kick
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Blue Belt Little Tigers and Juniors"
              url="https://www.youtube.com/embed/vFJKrLfnq24"
              techniques={(
                <div>
                  The breaking technique is Flying Side Kick
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Blue Belt Teen / Adult"
              url="https://www.youtube.com/embed/tmjsTvDnGUk"
              techniques={(
                <div>
                  The breaking technique is Skipping Side Kick
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Purple Belt All"
              url="https://www.youtube.com/embed/mlgQM_pzH0I"
              techniques={(
                <div>
                  The breaking technique is Turning Back Hook Kick
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Red Belt All"
              url="https://www.youtube.com/embed/KtwFbxe-Pzs"
              techniques={(
                <div>
                  The breaking technique is Skipping Roundhouse Kick
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="High Red Belt All"
              url="https://www.youtube.com/embed/wzhEAjJgmpI"
              techniques={(
                <div>
                  The breaking technique is Turning Roundhouse Kick
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Brown Belt Little Tigers and Juniors"
              url="https://www.youtube.com/embed/LOPR3XWakKc"
              techniques={(
                <div>
                  The breaking technique is Jumping Front Kick
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="Brown Belt Teen / Adult"
              url="https://www.youtube.com/embed/noiA_81IxTs"
              techniques={(
                <div>
                  The breaking technique is Jumping Roundhouse Kick
                </div>
              )} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Video
              title="High Brown Belt All"
              url="https://www.youtube.com/embed/YTa0wbqp1dY"
              techniques={(
                <div>
                  The breaking technique is Jumping Back Kick
                </div>
              )} />
          </div>
        </div>
      </div>
    );
  }


  getKnowledge() {
    return (
      <div className="box">
        <hr />
        <h2 className="intro-text text-center" style={{ fontWeight: "bold" }}>
          Knowledge
                </h2>
        <div className="panel panel-default" style={{ width: "98%", marginLeft: "1%" }}>
          <div className="panel-body">

            <strong>Select your current belt to download a PDF copy of the Knowledge requirements for your current level.</strong>
            <ul>
              <li className="knowledge-item"><a href="/curriculum/01_white.pdf" target="_new" className="knowledge-item-link">White Belt</a></li>
              <li className="knowledge-item"><a href="/curriculum/02_yellow_orange.pdf" target="_new" className="knowledge-item-link">Yellow and Orange Belt</a></li>
              <li className="knowledge-item"><a href="/curriculum/03_green_blue.pdf" target="_new" className="knowledge-item-link">Green and Blue Belt</a></li>
              <li className="knowledge-item"><a href="/curriculum/04_purple.pdf" target="_new" className="knowledge-item-link">Purple Belt</a></li>
              <li className="knowledge-item"><a href="/curriculum/05_red.pdf" target="_new" className="knowledge-item-link">Red Belt</a></li>
              <li className="knowledge-item"><a href="/curriculum/06_high_red.pdf" target="_new" className="knowledge-item-link">High Red Belt</a></li>
              <li className="knowledge-item"><a href="/curriculum/07_brown_highbrown.pdf" target="_new" className="knowledge-item-link">Brown and High Brown Belt</a></li>
              <li className="knowledge-item"><a href="/curriculum/08_1_Dan.pdf" target="_new" className="knowledge-item-link">1 Dan</a></li>
              <li className="knowledge-item"><a href="/curriculum/09_2_Dan.pdf" target="_new" className="knowledge-item-link">2 Dan</a></li>
              <li className="knowledge-item"><a href="/curriculum/10_3_Dan.pdf" target="_new" className="knowledge-item-link">3 Dan</a></li>
              <li className="knowledge-item"><a href="/curriculum/brief_history.pdf" target="_new" className="knowledge-item-link">A Brief History of Taekwondo</a></li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Programs;