import React, { Component } from 'react';

const initialState = {
  email: '',
  phone: '',
  comments: '',
  name: '',
  errors: {
    email: '',
    phone: '',
    comments: ''
  },
  sent: false,
  loading: false
};

export default class Contact extends Component<any, any>{

  constructor(props: any) {
    super(props);
    this.state = initialState;

    this.updateField = this.updateField.bind(this);
    this.submit = this.submit.bind(this);
  }

  updateField(e: any) {
    const id = e.target.id;
    const value = e.target.value;
    const ns: any = this.state;
    ns[id] = value;
    return this.setState(ns);
  }

  async submit() {
    const st = this.state;
    let errFound = false;
    if (st.email === '') {
      st.errors.email = 'Email cannot be blank!';
      errFound = true;
    }
    if (st.comments === '') {
      st.errors.comments = 'Your message cannot be blank!';
      errFound = true;
    }
    if (errFound) {
      return this.setState(st);
    }
    //send messsage
    this.setState({ loading: true }, async () => {
      const data = {
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        body: this.state.comments,
        subject: "Question",
        site: 'kiantkd'
      };

      const url = 'https://rmju07mwv1.execute-api.us-east-1.amazonaws.com/dev/mail';

      fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
        .then((response) => response.json())
        .then(() => {
          let newState = initialState;
          newState.sent = true;
          newState.loading = false;
          this.setState(newState);
        })
        .catch((err) => {
          console.log(err);
          let newState = initialState;
          newState.sent = true;
          newState.loading = false;
          this.setState(newState);
        })
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="box">
          <hr />
          <h2 className="intro-text text-center">
            <strong>Contact Us</strong>
          </h2>
          <hr />
          <div id="contact-container">
            <div className="glyphicon glyphicon-repeat normal-right-spinner" style={{ textAlign: 'center', width: "100%" }}></div>
          </div>
        </div>
      )
    }
    if (this.state.sent) {
      return (
        <div className="box">
          <hr />
          <h2 className="intro-text text-center">
            <strong>Contact Us</strong>
          </h2>
          <hr />
          <div id="contact-container">
            <p className="intro-text text-center">Your message has been sent! We will respond as soon as we can!</p>
          </div>
        </div>
      )
    }
    return (
      <div className="box">
        <hr />
        <h2 className="intro-text text-center">
          <strong>Contact Us</strong>
        </h2>
        <hr />
        <div id="contact-container">
          <hr className="visible-xs" />
          <div className="form-group">
            <input type="text" id="name" placeholder="Your Name" className="form-control" value={this.state.name} onChange={this.updateField} />
          </div>
          <div className="form-group">
            <input
              type="email"
              id="email"
              placeholder="Your Email Address"
              className="form-control"
              value={this.state.email}
              onChange={this.updateField} />
            {this.state.errors.email !== '' && (<div className="formError">{this.state.errors.email}</div>)}
          </div>
          <div className="form-group">
            <input type="phone" id="phone" placeholder="Contact Number" className="form-control" value={this.state.phone} onChange={this.updateField} />
          </div>
          <div className="form-group">
            <textarea className="form-control" rows={3} placeholder="Your comments or questions" id="comments" value={this.state.comments} onChange={this.updateField}></textarea>

            {this.state.errors.comments !== '' && (<div className="formError">{this.state.errors.comments}</div>)}
          </div>
          <button id="contact-submit" className="btn btn-block btn-primary" value="Submit" onClick={this.submit}>Submit</button>
          <div style={{ textAlign: 'center' }}>
            <a href="mailto:kiantkd@gmail.com" className="small">Or Email Us Directly</a>
          </div>
        </div>
      </div>
    )
  }
};