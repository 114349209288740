import React, { Component } from 'react';

import scheduleImage from './img/schedule/schedule.png';

class Schedule extends Component {

  render() {
    return (
      <div className="row">
        <div className="box">
          <div className="col-12">
            <hr />
            <h2 className="intro-text text-center">
              <strong>Schedule</strong>
            </h2>
            <hr />
            <p style={{ textAlign: 'center', marginTop: 40 }}>
              <a href="/schedule.pdf" target="_new">
                <img src={scheduleImage} alt="schedule" style={{ maxWidth: '100%' }} />
              </a>
            </p>

            <p style={{ textAlign: 'center', marginTop: 40 }}>
              <a href="http://iframesrc=https://www.google.com/calendar/embed?showTitle=0&amp;showPrint=0&amp;showTabs=0&amp;showCalendars=0&amp;showTz=0&amp;height=500&amp;wkst=1&amp;bgcolor=%23FFFFFF&amp;src=n7e8k8mespphh6f6khcbiupjs4%40group.calendar.google.com&amp;color=%23182C57&amp;ctz=America%2FNew_Yorkstyle=border-width:0width=500height=500frameborder=0scrolling=no/iframe">
                <iframe title="Kian Taekwondo Schedule" style={{ borderWidth: 0 }} src="https://www.google.com/calendar/embed?showTitle=0&amp;showPrint=0&amp;showTabs=0&amp;showCalendars=0&amp;showTz=0&amp;height=500&amp;wkst=1&amp;bgcolor=%23FFFFFF&amp;src=n7e8k8mespphh6f6khcbiupjs4%40group.calendar.google.com&amp;color=%23182C57&amp;ctz=America%2FNew_York"
                  width="500" height="500" frameBorder="0" scrolling="no"></iframe></a>
            </p>
          </div>
        </div>
      </div>


    );
  }
}

export default Schedule;