import React, { Component } from "react";
import { Modal } from "react-bootstrap";

interface IVideoProps {
  title?: string;
  subtitle?: string;
  techniques?: any;
  url: string;
}

interface IVideoState {
  showTechniquesModal: boolean;
}

export default class Video extends Component<IVideoProps, IVideoState>{

  constructor(props: any) {
    super(props);

    this.state = {
      showTechniquesModal: false
    }

    this.toggleTechniquesModal = this.toggleTechniquesModal.bind(this);
  }

  render() {
    return (
      <div className="card" style={{ width: "98%", marginLeft: "1%", marginBottom: 20 }}>
        <div className="card-body">

        {this.props.title && this.props.title !== "" && (<h3 className="text-center card-title>">{this.props.title}</h3>)}
        {this.props.subtitle && this.props.subtitle !== "" && (<h4 className="text-center card-subtitle>">{this.props.subtitle}</h4>)}

          <div>
            <div style={{ textAlign: "center" }}>
              <iframe style={{width: "100%"}} width="560" height="315" src={this.props.url} frameBorder="0" title={this.props.title? this.props.title : "Kian TKD"} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
            {this.props.techniques && (
              <div>
                <button className="btn btn-block btn-primary" onClick={this.toggleTechniquesModal}>SHOW TECHNIQUES</button>
              </div>
            )}
          </div>
        </div>
        <Modal show={this.state.showTechniquesModal} onHide={this.toggleTechniquesModal} backdrop="static">
          <Modal.Header>
            <Modal.Title>Techniques</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="techniques">
              {this.props.techniques}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-primary" onClick={this.toggleTechniquesModal}>Close</button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }

  toggleTechniquesModal() {
    this.setState({ showTechniquesModal: !this.state.showTechniquesModal });
  }
};