import React, { Component } from 'react';

import childrenImage from './img/Childrens-Program-1024x473.jpg';
import adultImage from './img/testing.jpg';
import sparringImage from './img/sparring.jpg';

class Programs extends Component {

  render() {
    return (
      <div id="programs">
        <div className="row">
          <div className="box">
            <div className="col-12">
              <hr />
              <h2 className="intro-text text-center">
                <strong>Children's Program</strong>
              </h2>
              <hr />
              <img className="img-responsive img-border img-left" src={childrenImage} height="50%" width="50%" alt="Childrens' Program" />
              <p>Taekwondo is a mental and physical discipline. Our Childrens' Programs are tailored to your child's age and skill level. They are open to children as young as three years old. The goal of our programs is to help children develop discipline as well as valuable self-defense skills. Children often experience an improvement in their academic performance as they learn to focus on objectives and work towards goals. Taekwondo provides your child with invaluable lifelong benefits of both mental and physical strength and discipline.</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="box">
            <div className="col-12">
              <hr />
              <h2 className="intro-text text-center">
                <strong>Adult / Teen Program</strong>
              </h2>
              <hr />
              <img className="img-responsive img-border img-left" src={adultImage} height="50%" width="50%" alt="Teen / Adult Program" />
              <p>Respect, trust, and open communication are the foundation of strong families. Taekwondo training promotes values such as honesty, loyalty, and cooperation. Each is an essential component in maintaining a good family structure. Parents also gain discipline for their children and themselves. And for those adults, who always wanted to learn martial arts, come and try our adult classes. Our fun and exciting adult classes will challenge and invigorate you as many of our adult students would attest. It is never too late to begin!</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="box">
            <div className="col-12">
              <hr />
              <h2 className="intro-text text-center">
                <strong>Sparring</strong>
              </h2>
              <hr />
              <img className="img-responsive img-border img-left" src={sparringImage} alt="Sparring" />
              <p>Sparring is both an important and fun component of any Taekwondo curriculum. It is also a great workout. Our students learn the rules of World Taekwondo Federation Sparring- the same rules that govern the matches in the Olympic Games.Once students develop their basic sparring skill sets, emphasis is placed on strategy and counterattacks. Our goal is to develop students into well-trained fighters capable of performing at top levels at local and National competitions. At Kian Taekwondo, students are required to master the basics of self-control before they are allowed to take part in sparring classes. Safety and respect are of highest importance in our sparring program. Students learn to take care of each other during class. They also learn to show respect to their coaches, classmates, referees, and other competitors. Our sparring program develops well-rounded and respectful competitors who are a force to be reckoned with at many state and National competitions.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Programs;