import React from 'react';

import banner from './img/banner-background.png';
import kian from './img/kian.png';
import kukkiwon from './img/kukkiwon.png';

function Header() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-2">
          <img alt="Kian Taekwondo Logo" src={kian} style={{width: "100%"}} />
        </div>
        <div className="col-8" style={{ backgroundImage: `url(${banner})`, backgroundRepeat: 'no-repeat' }}>
          <div className="brand" style={{ color: 'red' }}><a href="/" style={{textDecoration: "none", color: "red"}}>Kian Taekwondo</a></div>
          <div className="address-bar" style={{ fontWeight: 'bold' }}>
            236 North Broadway | Salem, NH 03079 | <a href="tel:+16038945425">603.894.5425</a>
          </div>
          <div className="address-bar smaller"><em>Leap to your Success!</em></div>
        </div>
        <div className="col-2">
          <img alt="Kukkiwon Logo" src={kukkiwon} style={{width: "100%"}} />
        </div>
      </div>
    </div>
  );
}

export default Header;
