import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

//css
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./css/styles.css";


//pages
import App from "./App";
import Home from "./Home";
import About from "./About";
import Programs from "./Programs";
import Instructors from "./Instructors";
import Offers from "./Offers";
import Schedule from "./Schedule";
import Curriculum from "./Curriculum";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App>
        <Switch>
          <Route path="/" exact={true} component={Home} />
          <Route path="/about" exact={true} component={About} />
          <Route path="/programs" exact={true} component={Programs} />
          <Route path="/instructors" exact={true} component={Instructors} />
          <Route path="/schedule" exact={true} component={Schedule} />
          <Route path="/offers" exact={true} component={Offers} />
          <Route path="/curriculum" exact={true} component={Curriculum} />
        </Switch>
      </App>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
