import React, { Component } from 'react';

//images
import seoImage from './img/instructors/seo.jpg';
import erin from './img/instructors/erin.jpg';
import vicki from './img/instructors/vicki.jpg';
import kevin from './img/instructors/kevin.jpg';
import richard from './img/instructors/richard.jpg';
import kelly from './img/instructors/kelly.jpg';
import cat from './img/instructors/cat.jpg';
import savannah from './img/instructors/savannah.jpg';
import savannah_e from './img/instructors/savannah_e.jpg';
import charles from './img/instructors/charles.jpg';
import dave from './img/instructors/dave.jpg';
import camryn from './img/instructors/camryn.jpg';

class Instructors extends Component {

    render() {
        return (
            <div className="row">
                <div className="box">
                    <div className="col-12">
                        <hr />
                        <h2 className="intro-text text-center">
                            <strong>Our Instructors</strong>
                        </h2>
                        <hr />
                        <p style={{ textAlign: 'center' }}>
                            <a href={seoImage}>
                                <img className="size-full wp-image-44" src={seoImage} width="291" height="199" alt="Grand Master Seo" />
                            </a>
                        </p>
                        <h2 style={{ textAlign: 'center' }}>Grand Master Wonil Seo - Owner, Master</h2>
                        <p style={{ textAlign: 'center' }}>
                            Grand Master Wonil Seo is a Kukkiwon certified 7 Dan and the founder of Kian Taekwondo in Salem, NH. He is a multiple national and international Taekwondo champion and has received numerous awards and appointments to office from many organizations.
                  </p>

                        <div className="row" style={{ textAlign: 'center' }}>
                            <div className="col-lg-4 col-sm-12">
                                <img src={richard} width="200" height="150" alt="Master Richard Vecchi" />
                                <p><small><strong>Richard Vecchi</strong><br />5 Dan Master</small></p>
                            </div>
                            <div className="col-lg-4 col-sm-12">
                                <img src={kelly} width="200" height="150" alt="Master Kelly Hamel" />
                                <p><small><strong>Kelly Hamel</strong><br />5 Dan Master</small></p>
                            </div>
                            <div className="col-lg-4 col-sm-12">
                                <img src={erin} width="200" height="150" alt="Master Erin Lewis" />
                                <p><small><strong>Erin Lewis</strong><br />4 Dan Master</small></p>
                            </div>
                        </div>

                        <div className="row" style={{ textAlign: 'center' }}>
                            <div className="col-lg-4 col-sm-12">
                                <img src={vicki} width="200" height="150" alt="Master Vicki Eaton" />
                                <p><small><strong>Vicki Eaton </strong><br />4 Dan Master</small></p>
                            </div>
                            <div className="col-lg-4 col-sm-12">
                                <img src={kevin} width="200" height="150" alt="Master Kevin Eaton" />
                                <p><small><strong>Kevin Eaton</strong><br />4 Dan Master</small></p>
                            </div>
                            <div className="col-lg-4 col-sm-12">
                                <img src={cat} width="200" height="150" alt="Master Calista Tait" />
                                <p><small><strong>Calista Tait</strong><br />4 Dan Master</small></p>
                            </div>
                        </div>

                        <div className="row" style={{ textAlign: 'center' }}>
                            <div className="col-lg-4 col-sm-12">
                                <img src={dave} width="200" height="150" alt="Master David Sevigny" />
                                <p><small><strong>David Sevigny</strong><br />4 Dan Master</small></p>
                            </div>
                            <div className="col-lg-4 col-sm-12">
                                <img src={savannah} width="200" height="150" alt="Instructor Savannah Reddy" />
                                <p><small><strong>Savannah Reddy</strong><br />3 Dan and Class C Instructor</small></p>
                            </div>
                            <div className="col-lg-4 col-sm-12">
                                <img src={savannah_e} width="200" height="150" alt="Instructor Savannah Eaton" />
                                <p><small><strong>Savannah Eaton</strong><br />3 Dan and Class C Instructor</small></p>
                            </div>
                        </div>
                        <div className="row" style={{ textAlign: 'center' }}>
                            <div className="col-lg-4 col-sm-12 offset-lg-2">
                                <img src={camryn} width="200" height="150" alt="Instructor in Training Camryn Herrick" />
                                <p><small><strong>Camryn Herrick</strong><br />3 Dan and Instructor in Training</small></p>
                            </div>
                            <div className="col-lg-4 col-sm-12">
                                <img src={charles} width="200" height="150" alt="Instructor in Training Charles Hudson" />
                                <p><small><strong>Charles Hudson</strong><br />2 Dan and Instructor in Training</small></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Instructors;