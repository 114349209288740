import React from "react";

export default function Map() {
  return (
    <div className="box">
      <hr />
      <h2 className="intro-text text-center">
        <strong>Map</strong>
      </h2>
      <hr />
      <iframe title="Kian Taekwondo in Salem, NH Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2927.8329566830703!2d-71.23625044868878!3d42.79188111561114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e3aa53d5bf3f95%3A0x619193c75f197042!2s236+N+Broadway%2C+Salem%2C+NH+03079!5e0!3m2!1sen!2sus!4v1550969417503"
        width="230" height="250" frameBorder="0" style={{ border: 0 }} allowFullScreen></iframe>
    </div>
  )
}