import React from "react";

export default function SOM(props: any){

  return (
      <div className="box">
        <div className="row">
          <div className="col-12">
            <hr />
            <h2 className="intro-text text-center">
              <strong>Student of the Month</strong>
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <hr />
            <img className="img-responsive img-border img-left" style={{width: "100%"}} src={props.image} alt="Student of the Month!" />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <hr className="visible-xs" />
            <p>Kian Taekwondo is proud to announce <strong>{props.studentName}</strong> as our Student of the Month!</p>
          </div>
        </div>
      </div>
  )
}