import React, { Component } from "react";
import Header from "./Header";
import NavBar from "./NavBar";
import Contact from "./Contact";
import Map from "./Map";
import Hours from "./Hours";

class App extends Component {

  render() {
    return (
      <div id="appPage">
        <Header />
        <NavBar />
        <div className="row">
          <div className="col-lg-9 col-sm-12">
            <main>
              {this.props.children}
            </main>
          </div>
          <div className="col-lg-3 col-sm-12">
            <Contact />
            <Map />
            <Hours />
          </div>
        </div>

      </div>
    );
  }
}

export default App;